.error-message {
    background-color: #d32f2f !important;
    color: white;
}

.clickable {
    cursor: pointer;
}

.snippet {
    font-style: italic;
}

.MuiSelect-select.MuiInputBase-input {
    display: flex;
    align-items: center;
}

.permissionSubjectName {
    text-transform: capitalize;
}

.folder-link {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.folder-link:hover {
    text-decoration: underline;
}

ol.MuiBreadcrumbs-ol {
    align-items: flex-end;
}

.vertically-centered {
    display: flex;
    align-items: center;
}

.horizontally-centered {
    display: flex;
    justify-content: center;
}

.horizontally-right {
    display: flex;
    justify-content: flex-end;
}

div.error {
    background-color: #d32f2f;
    color: white;
}

.workflow-step-complete {
    color: #28a745;
}
div.workflow-step-dependency-completed {
    border-color: #28a745;
}

.workflow-step-incomplete {
    color: #ffc107;
}
div.workflow-step-dependency-incomplete {
    border-color: #ffc107;
}

svg.workflow-step-complete, svg.workflow-step-incomplete {
    font-size: 2rem;
}

button.workflow-step-status-icon .MuiButton-label {
    flex-direction: column;
}

.workflow-step .MuiCardContent-root, .workflow-step .MuiCardContent-root:last-child {
    padding: 8px;
}

div.user-avatar {
    width: 32px;
    height: 32px;
    font-size: 16px;
}

div.email-user-avatar {
    width: 24px;
    height: 24px;
}

.user-avatar.user-avatar-collection {
    margin-right: 0.25rem;
}

.workflow-step-comment .user-avatar {
    display: inline-flex;
    height: 24px;
    width: 24px;
    font-size: 1rem;
    margin-left: 0;
}

.workflow-step ul.MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
}

.workflow-step .MuiListItemText-multiline {
    margin-top: 0;
    margin-bottom: 0;
}

ul.sublist {
    padding-left: 2rem;
}

.workflow-step-dependency {
    margin-right: 0.5rem;
}

.workflow-step-highlighted .workflow-step-name {
    font-weight: bold;
}

.collapsed, .expanded {
    transform: rotate(0deg);
    margin-left: auto;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.expanded {
    transform: rotate(180deg);
}

span.placeholder {
    color: rgba(0, 0, 0, 0.54);
    margin-right: 1rem;
    font-style: italic;
}

svg.folder {
    font-size: 18px;
}

button.menu svg {
    font-size: 2rem;
}

.clickable {
    cursor: pointer;
}

.sales .MuiCard-root {
    min-height: 240px;
    max-width: 300px;
}

.sales .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
}

img.hero {
    max-width: 100%;
}

p.MuiFormHelperText-root {
    margin-top: 0;
}

.MuiTableRow-root.selected {
    background-color: rgb(255, 227, 227) !important;
    color: black;
}

.MuiTableRow-root.selected td div div div span{
    color: #080505 !important;
}

table.data .MuiTableCell-root, table.nested-data .MuiTableCell-root {
    padding: 3px;
}

table.nested-data .MuiTableRow-root .MuiTableCell-root:first-child {
    padding-left: 0;
}

table.nested-data .MuiTableRow-root .MuiTableCell-root:first-child .MuiIconButton-root {
    padding: 0;
}

table.data .MuiTableRow-root .MuiTableCell-root:first-child {
    padding-left: 1rem;
}

div.pointer {
    cursor: pointer;
}

iframe {
    border: none;   
}

th.MuiTableCell-head {
    font-weight: bold;
}

.fylerText {
    color: #ef465d;
}

.knowledge .MuiCard-root {
    min-height: 240px;
    max-width: 500px;
    background-color: pink;
}

.knowledge .MuiCard-root:hover {
    cursor: pointer;
    background-color: #fa96a8;
    text-decoration: underline;
}

.knowledge .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
}